// * Importing necessary  Styles.
// **/
/*----- Fonts ----*/
@import url("./fonts//recoleta//stylesheet.css");
@import url("./fonts/gordita/stylesheet.css");
@import url("https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap");
/*----- Bootstrap Icons ----------*/
@import url("./fonts/bootstrap-icons/font-css.css");
/*---- Font Awesome Icons -------*/
@import url("./fonts/font-awesome/css/all.min.css");
/*---- react modal video -------*/
@import "node_modules/react-modal-video/scss/modal-video.scss";

// All Scss File
@import "./scss/animate.min.scss";
@import "./scss/custom-animation.scss";
@import "./scss/framework.scss";
@import "./scss/navbar.scss";
@import "./scss/slick-custom";
@import "./scss/style.scss";
@import "./scss/custom-reactify.scss";
@import "./scss/responsive.scss";
